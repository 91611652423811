import type { EventArgType } from './helpers.EventEmitter.ts';
import { contextSelector } from 'o365.controls.NavBar.js';
import messenger from './helpers.GlobalMessenger.ts';
import EventEmitter from './helpers.EventEmitter.ts';
import API from './data.api.ts';

declare global {
    interface Window {
        __o365_shared?: {
            onContextChanged?: (pCallback: ContextEvents['Change']) => void
        }
    }
}

export type ContextResponse = {
    name: string,
    id: number,
    idPath: string,
    orgUnit: string,
    isDomain: boolean,
    domainId: number | null,
}

export type ContextEvents = {
    'Change': (ctx: ContextResponse) => void
};

export type SetContextOptions = {
    userTriggered?: boolean;
    preventSaving?: boolean;
    contextDropdownItems?: {
        domain?: string;
        domainId?: number;
        orgUnit: string;
        orgUnitId: number;
        registerId?: number;
    };
};

class Context {
    name: string;
    id: number;
    idPath: string;
    orgUnit: string;
    domainId: number | null;
    accessIdPath: string;
    callbacksMap = new Map<Symbol, Function>();
    eventHandler = new EventEmitter<ContextEvents>();

    #lastEmittedChangeArg: ContextResponse | null = null;

    get isDomain() {
        return this.domainId === this.id;
    }

    constructor() {
        if (!contextSelector) {
            console.warn("Failed to initialize Context. contextSelector object not found");
            throw new TypeError('Failed to initialize Context. contextSelector object not found');
        }

        const obj = contextSelector.getSessionContextObject();
        this.id = obj.ID;
        this.idPath = obj.IdPath;
        this.name = obj.name;
        this.orgUnit = obj.name;
        this.domainId = obj.Domain_ID;
        this.accessIdPath = obj.AccessIdPath;
        
        this.#lastEmittedChangeArg = { id: this.id, idPath: this.idPath, name: this.name, orgUnit: this.orgUnit, domainId: this.domainId, isDomain: this.isDomain };;

        contextSelector.attachEvent("onContextChanged", (obj: any) => {
            const shouldFireChangedEvent = this.id !== obj.ID;
            this.id = obj.ID;
            this.idPath = obj.IdPath;
            this.name = obj.OrgUnit ?? obj.name;
            this.orgUnit = obj.OrgUnitName;
            this.domainId = obj.Domain_ID;
            this.accessIdPath = obj.AccessIdPath;

            const args = { id: this.id, idPath: this.idPath, name: this.name, orgUnit: this.orgUnit, domainId: this.domainId, isDomain: this.isDomain };
            this.#lastEmittedChangeArg = args;

            if (shouldFireChangedEvent || obj.forceChangeEvent) {
                this.#emit("Change", args);

                /* DEPRECATED */
                Array.from(this.callbacksMap.values()).forEach(acceptCallback => {
                    acceptCallback({ id: this.id, idPath: this.idPath, name: this.name, orgUnit: this.orgUnit });
                });
            }
        });

        messenger.on((pMesssage: any) => {
            if (pMesssage.event === "ContextChange") {
                context.setContext(pMesssage.id);
            }
        })
    }

    async setContext(orgUnitId: number, opts: SetContextOptions = {}) {
        return new Promise(resolve => {
            contextSelector.updateContext(orgUnitId, { preventSaving: opts?.preventSaving ?? false }, opts?.userTriggered, null, (_obj: any) => {
                const contextRespose = { id: this.id, name: this.name, idPath: this.idPath, orgUnit: this.orgUnit };

                if (opts.contextDropdownItems) {
                    contextSelector.setNavigateTo({
                        domainOrgUnit: opts.contextDropdownItems.domain,
                        domainOrgUnit_ID: opts.contextDropdownItems.domainId,
                        orgUnit: opts.contextDropdownItems.orgUnit,
                        orgUnit_ID: opts.contextDropdownItems.orgUnitId,
                        registerId: opts.contextDropdownItems.registerId,
                    });
                }

                resolve(contextRespose);
            });
        });
    }

    async updateContextFromDB() {
        const currentContext = await API.requestPost('/nt/api/user/orgunitcontext/get');
        return this.setContext(currentContext.id);
    }

    /**
     * Executes provided callback upon context changes. Returns function for canceling the listener
     */
    onChanged(callback: (res: ContextResponse) => void) {
        console.warn("This method is deprecated. Please use event emitter instead. Example: context.on('Change', ctx => {})");
        const uid = Symbol();
        this.callbacksMap.set(uid, callback);
        return () => {
            this.callbacksMap.delete(uid);
        };
    }

    on<K extends keyof ContextEvents>(event: K, listener: ContextEvents[K]) {
        const callback = this.eventHandler.on(event, listener);

        if (event === "Change" && this.#lastEmittedChangeArg !== null) {
            listener(this.#lastEmittedChangeArg);
        }

        return callback;
    }

    once<K extends keyof ContextEvents>(event: K, listener: ContextEvents[K]) {
        return this.eventHandler.once(event, listener);
    }

    off<K extends keyof ContextEvents>(event: K, listener: ContextEvents[K]) {
        return this.eventHandler.off(event, listener);
    }

    #emit = <K extends keyof ContextEvents>(event: K, ...args: EventArgType<ContextEvents, K>) => {
        return this.eventHandler.emit(event, ...args);
    }
}

const context = new Context();

export { context as default, Context }

